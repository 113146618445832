import Axios from '@/axios'
// 提交
export const startProcess = function (cond = {}) {
  return Axios.post('/invoiceApplication/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/invoiceApplication/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/invoiceApplication/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/invoiceApplication/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond = {}) {
  return Axios.post('/invoiceApplication/add', cond)
}
// 删除
export const deleteExpense = function (cond = {}) {
  return Axios.post('/invoiceApplication/delete', cond)
}
// 撤回
export const reject = function (cond = {}) {
  return Axios.post('/invoiceApplication/reject', cond)
}
// 驳回提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/invoiceApplication/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond = {}) {
  return Axios.post('/invoiceApplication/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond = {}) {
  return Axios.post('/invoiceApplication/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond = {}) {
  return Axios.post('/invoiceApplication/disposeTask', cond)
}

//导出
export const exporting = function (cond) {
  return Axios({
    url: '/invoiceApplication/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//明细列表
export const invoiceApplicationAllList = function (cond = {}) {
  return Axios.post('/invoiceApplication/allList', cond)
}
//统计值
export const getApplyStatistics = function (cond = {}) {
  return Axios.post('/invoiceApplication/getApplyStatistics', cond)
}
//财务开票
export const financialInvoicingApply = function (cond = {}) {
  return Axios.post('/invoiceApplication/financialInvoicingApply', cond)
}
//发票历史开票信息
export const historyDataList = function (cond = {}) {
  return Axios.post('invoiceApplication/historyDataList', cond)
}

// 合同发票申请明细导出
export const invoiceApplicationListExport = function (cond = {}) {
  return Axios({
    url: '/invoiceApplication/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

// 未立项合同发票申请
// 获取用户  副总 GENERAL_MANAGER  股东层  SHAREHOLDER
export const queryUsersByPostLevel = function (cond = {}) {
  return Axios.post('/sysStaff/queryUsersByPostLevel', cond)
}
// 获取所有合同列表
export const allListAgreement = function (cond = {}) {
  return Axios.post('/agreement/allListAgreement', cond)
}
// 已立项未签合同转已立项已签合同
export const convertProjectToContractSigned = function (cond = {}) {
  return Axios.post('/invoiceApplication/convertProjectToContractSigned', cond)
}
// 补签项目和合同(未立项未签合同（后补）)
export const editUnsignedContracts = function (cond = {}) {
  return Axios.post('/invoiceApplication/editUnsignedContracts', cond)
}
