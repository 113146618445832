import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

var getForm = (options = {}) => {
  var originalForm = store.state.project.originalForm
  var projectDeptDeleteList = []
  var projectDeptBudgetDeleteList = []

  var originalProjectDeptBudgetList = []
  var chckedProjectDeptBudgetList = []

  var memberData = store.state.project.memberData.deepClone()
  var selectedMember = []

  var stageData = store.state.project.stageData.deepClone()
  var projectStagePlanCollect = store.state.project.projectStagePlanCollect
  var projectStageTaskList = store.state.project.projectStageTaskList
  var chckedProjectStagePlanList = []
  var chckedProjectStageTasktList = []

  var form = {
    projectFileList: store.state.project.projectFileList,
    projectInformation: {
      ...store.state.project.projectInformation,
      projectOutsourceDeleteList: [],
      projectCostPlanFileList: [],
    },
    // .combination(
    //   store.state.project.projectInformation
    // ),
    projectDisposableExpenses: store.state.project.projectDisposableExpenses,
    projectFileDeleteList: [],
    projectDeptMemberCollectList: [],
    projectDeptMemberDeleteList: [],
    projectStagePlanCollect: {
      projectStageTaskDeleteList: [],
      stagePlanDeleteList: [],
      stagePlanList: [],
    },
    projectCostPlanFileDeleteList: [],
  }
  store.state.project.projectCostPlanFileList.some(r => {
    form.projectInformation.projectCostPlanFileList.push(
      r.combination({
        projectId: form.projectInformation.id,
      })
    )
  })

  originalForm.projectInformation.projectCostPlanFileList.some(res => {
    if (
      !store.state.project.projectCostPlanFileList.some(r => {
        return r.id == res.id
      })
    ) {
      form.projectCostPlanFileDeleteList.push(res.id)
    }
  })

  originalForm.projectInformation.projectOutsourceList.some(res => {
    if (
      !form.projectInformation.projectOutsourceList.some(r => {
        return r.id == res.id
      })
    ) {
      form.projectInformation.projectOutsourceDeleteList.push(res.id)
    }
  })

  originalForm.projectFileList.some(res => {
    if (
      !form.projectFileList.some(r => {
        return r.id == res.id
      })
    ) {
      form.projectFileDeleteList.push(res.id)
    }
  })

  originalForm.projectInformation.projectDeptList.some(res => {
    if (
      !form.projectInformation.projectDeptList.some(r => {
        return r.deptId == res.deptId
      })
    ) {
      projectDeptDeleteList.push(res.id)
    }
    // res.projectDeptBudgetList.some(r=>{
    //     originalProjectDeptBudgetList.push(r)
    // })
  })
  form.projectInformation.projectDeptDeleteList = projectDeptDeleteList

  // form.projectInformation.projectDeptList.some(res=>{
  //     res.projectDeptBudgetList.some(r=>{
  //         chckedProjectDeptBudgetList.push(r)
  //     })
  // });

  // originalProjectDeptBudgetList.some(res=>{
  //     if(!chckedProjectDeptBudgetList.some(r=>{return r.postId==res.postId})){
  //         projectDeptBudgetDeleteList.push(res.id)
  //     };
  // });
  form.projectInformation.projectDeptBudgetDeleteList = projectDeptBudgetDeleteList

  for (let k in memberData) {
    let obj = {
      deptId: k,
      projectDeptMemberList: [],
    }

    memberData[k].some(r => {
      obj.projectDeptMemberList.push({
        deptId: k,
        id: r.projectMemberId,
        memberId: r.id,
        projectRole: r.projectRole,
        projectId: options.id,
        joinDate: r.joinDate,
        joinResponsibility: r.joinResponsibility,
      })
      if (r.projectMemberId) {
        selectedMember.push(r.projectMemberId)
      }
    })

    form.projectDeptMemberCollectList.push(obj)
  }
  originalForm.projectDeptMemberList.some(res => {
    if (
      !selectedMember.some(r => {
        return r == res.id
      })
    ) {
      form.projectDeptMemberDeleteList.push(res.id)
    }
  })

  for (let k in stageData) {
    var arr = stageData[k]

    arr.some(r => {
      r.projectStageTaskList = r.children
      chckedProjectStagePlanList.push(r.id)
      r.children.some(n => {
        chckedProjectStageTasktList.push(n.id)
      })

      form.projectStagePlanCollect.stagePlanList.push(r)
    })
  }

  originalForm.projectStagePlanCollect.some(res => {
    if (
      !chckedProjectStagePlanList.some(r => {
        return r == res.id
      })
    ) {
      form.projectStagePlanCollect.stagePlanDeleteList.push(res.id)
    }
  })

  originalForm.projectStageTaskList.some(res => {
    if (
      !chckedProjectStageTasktList.some(r => {
        return r == res.id
      })
    ) {
      form.projectStagePlanCollect.projectStageTaskDeleteList.push(res.id)
    }
  })
  return form
}

export const listProject = function (cond = {}) {
  return Axios.post('/project/listProject', cond)
}

export const listBusinessnProject = function (cond = {}) {
  return Axios.post('/project/listBusinessnProject', cond)
}

export const listProjectNotPage = function (cond = {}) {
  return Axios.post('/project/listProjectByParentIdNotPage', cond)
}

//已审批状态编辑项目信息
export const editProject = function (cond = {}) {
  return Axios.post('/project/editProject', getForm(cond).combination(cond))
}
//暂存状态编辑
export const undeterminedEditProject = function (cond = {}) {
  return Axios.post('/project/undeterminedEditProject', getForm(cond).combination(cond))
}

export const projectStartImplement = function (cond = {}) {
  return Axios.post('/project/projectStartImplement', getForm(cond).combination(cond))
}

export const taskPlanSubmitProject = function (cond = {}) {
  return Axios.post('/project/taskPlanSubmitProject', getForm(cond).combination(cond))
}

//暂存状态编辑并提交
export const undeterminedEditOrSubmitProject = function (cond = {}) {
  return Axios.post('/project/undeterminedEditOrSubmitProject', getForm(cond).combination(cond))
}

//新增项目
export const creationProject = function (cond = {}) {
  var data = {
    projectInformation: store.state.project.projectInformation,
    projectFileList: store.state.project.projectFileList,
    projectDisposableExpenses: store.state.project.projectDisposableExpenses,
  }
  return Axios.post('/project/creationProject', data.combination(cond))
}
//新增并提交项目
export const creationOrSubmitProject = function (cond = {}) {
  var data = {
    projectInformation: store.state.project.projectInformation,
    projectFileList: store.state.project.projectFileList,
    projectDisposableExpenses: store.state.project.projectDisposableExpenses,
  }
  return Axios.post('/project/creationOrSubmitProject', data.combination(cond))
}

export const selectDetailById = function (id) {
  return Axios.post('/project/selectDetailById/' + id)
}

export const selectInformationDetailById = function (id) {
  return Axios.post('/project/selectInformationDetailById/' + id)
}

export const approve = function (cond = {}) {
  return Axios.post('/project/approve', cond)
}

export const del = function (id) {
  return Axios.post('/project/delete/' + id)
}

//通过审批
export const approvePass = function (cond = {}) {
  return Axios.post('/project/approvePass', cond)
}

//不通过审批
export const approveReject = function (cond = {}) {
  return Axios.post('/project/approveReject', cond)
}

//阶段完成1
export const stagePlanAccomplish = function (cond = {}) {
  return Axios.post('/projectStagePlan/stagePlanAccomplish', cond)
}

//阶段完成2
export const stagePlanDetailAccomplish = function (cond = {}) {
  return Axios.post('/projectStagePlan/stagePlanDetailAccomplish', cond)
}

//任务完成
export const stageTaskAccomplish = function (cond = {}) {
  return Axios.post('/projectStageTask/stageTaskAccomplish', cond)
}

//提交预估成本
export const costPlanSubmit = function (cond = {}) {
  return Axios.post('/project/costPlanSubmit', getForm(cond).combination(cond))
}

//移除项目成员
export const delProjectleave = function (cond = {}) {
  return Axios.post('/projectDeptMember/leave', cond)
}

// 预估成本方案列表
export const listCostPlan = function (cond = {}) {
  return Axios.post('/project/listCostPlan', cond)
}

// 项目成员变更记录
export const DeptMemberLogList = function (cond = {}) {
  return Axios.post('/projectDeptMemberLog/list', cond)
}

// 项目收入-确认收款
export const paymentPass = function (cond = {}) {
  return Axios.post('/agreementStagePlan/paymentPass', cond)
}

// 项目收入列表
export const listIncome = function (cond = {}) {
  return Axios.post('/project/listIncome', cond)
}

// 项目工时列表
export const listProjectToManhour = function (cond = {}) {
  return Axios.post('/project/listProjectToManhour', cond)
}

// 部门经理用户列表
export const listDepartmentStaff = function (cond = {}) {
  return Axios.post('/sysStaff/listDepartmentStaff', cond)
}

// 部门负责人用户列表
export const listDepartmentHeadStaff = function (cond = {}) {
  return Axios.post('/sysStaff/listDepartmentHeadStaff', cond)
}

// 阶段任务子级撤回
export const stageTaskReject = function (cond = {}) {
  return Axios.post('/projectStageTask/stageTaskReject', cond)
}

// 阶段任务父级撤回
export const stagePlanDetailReject = function (cond = {}) {
  return Axios.post('/projectStagePlan/stagePlanDetailReject', cond)
}

// 项目绩效详情
export const selectRuleDetailById = function (id) {
  return Axios.post('/projectRule/selectRuleDetailById/' + id)
}

// 项目绩效编辑
export const selectRuleEdit = function (cond = {}) {
  return Axios.post('/projectRule/edit', cond)
}

// 添加项目报销
export const addProjectCost = function (cond = {}) {
  return Axios.post('/projectExpense/addProjectCost', cond)
}

//项目报销查询列表（新）
export const getApplyForList = function (cond = {}) {
  return Axios.post('/projectExpense/getApplyForList', cond)
}

//项目报销查询详情（新）
export const getProjectExpense = function (id) {
  return Axios.post('/projectExpense/getProjectExpense/' + id)
}

//项目报销查询列表（新）
export const getAuditList = function (cond = {}) {
  return Axios.post('/projectExpense/getAuditList', cond)
}

//项目报销审核详情（新）
export const getProjectExpenseByAudit = function (cond) {
  return Axios.post('/projectExpense/getProjectExpenseByAudit', cond)
}

//项目报销审核（新）
export const auditProjectCost = function (cond) {
  return Axios.post('/projectExpense/auditProjectCost', cond)
}

//项目报销通过列表（新）
export const auditPassList = function (cond) {
  return Axios.post('/projectExpense/auditPassList', cond)
}

//项目确认报销（新）
export const affirmApply = function (cond) {
  return Axios.post('/projectExpense/affirmApply', cond)
}

//项目报销导出（新）
export const exporting = function (cond) {
  return Axios({
    url: '/projectExpense/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}

// 删除项目报销单（新）
export const removeProjectCost = function (id) {
  return Axios.post('/projectExpense/removeProjectCost/' + id)
}

// 项目报销按项目查详情列表
export const selectProjectExpenseDetailList = function (cond) {
  return Axios.post('/projectExpense/selectProjectExpenseDetailList', cond)
}

//项目确认报销（新）
export const getApplyStatistics = function (cond) {
  return Axios.post('/projectExpense/getApplyStatistics', cond)
}

// // 确认报销和报销审核，获取项目----移除
// export const projectList = function(cond) {
//   return Axios.post('/projectExpense/projectList', cond)
// }
// 确认报销和报销审核，获取项目 -----更改
export const projectList = function (cond) {
  return Axios.post('/project/listBusinessnProjectToName', cond)
}

// 项目绩效变更记录列表
export const projectRuleSelectRulelogList = function (cond = {}) {
  return Axios.post('/projectRule/logList', cond)
}

// 项目变更详情
export const projectRuleSelectLogDetailById = function (id) {
  return Axios.post('/projectRule/selectLogDetailById/' + id)
}

// 绩效规则明细
export const projectRuleSelectRuleDisDataDetailById = function (id) {
  return Axios.post('/projectPerformance/selectRuleDisDataDetailById/' + id)
}

// 计算项目绩效
export const projectRuleCalculateRuledata = function (id) {
  return Axios.post('/projectPerformance/calculateRuledata/' + id)
}

// 项目成本工时
export const projectRuleGetManhourCostList = function (cond = {}) {
  return Axios.post('/projectPerformance/getManhourCostList/', cond)
}

// 确定绩效分配方案
export const editOrSubmit = function (cond = {}) {
  return Axios.post('/projectPerformance/editOrSubmit', cond)
}

// 保存项目绩效
export const projectPerformanceEdit = function (cond = {}) {
  return Axios.post('/projectPerformance/edit', cond)
}

// 绩效规则变更记录列表
export const projectPerformanceLogList = function (cond = {}) {
  return Axios.post('/projectPerformance/logList', cond)
}

// 绩效规则变更记录明细
export const projectPerformanceSelectLogDetailById = function (id) {
  return Axios.post('/projectPerformance/selectLogDetailById/' + id)
}

// 项目导出
export const projectExport = function (cond = {}) {
  // return Axios.post("/project/export", (responseType: "blob"), cond);
  return Axios({
    url: '/project/export',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//获取项目报销列表
export const list = function (cond = {}) {
  return Axios.post('/projectExpense/list', cond)
}

//撤回
export const reject = function (cond = {}) {
  return Axios.post('/projectExpense/reject', cond)
}

//项目列表-项目经理及以上
export const listProjectByClassifyManagerAbove = function (cond = {}) {
  return Axios.post('/project/listProjectByClassifyManagerAbove', cond)
}
//工时-员工名称关联项目
export const listProjectByUser = function (cond = {}) {
  return Axios.post('/project/listProjectByUser', cond)
}

// 绩效工时获取当前用户所管理的项目列表
export const getProjectList = function (cond = {}) {
  return Axios.post('/project/listBusinessnProjectToName', cond)
}

// 查询项目经理数组（用于绩效工时明细列表查询）
export const getProjectManagerList = function (cond = {}) {
  return Axios.post('/project/listBusinessnProjectToManagerName', cond)
}

// 获取项目名称列表(用于输入时建议搜索)
export const getNameListNoPage = function (cond = {}) {
  return Axios.post('/project/nameListNoPage', cond)
}

// 项目统计金额
export const listProjectSumAmount = function (cond = {}) {
  return Axios.post('/project/listProjectSumAmount', cond)
}

// 项目经理名称列表不分页
export const deptClassifyManagerNameListNoPage = function (cond = {}) {
  return Axios.post('/project/deptClassifyManagerNameListNoPage', cond)
}

// 查项目可支配费用
export const selectDetailByProjectId = function (cond) {
  return Axios.post('/projectDisposableExpenses/selectDetailByProjectId/' + cond)
}

//发票申请-只有关联合同的项目列表
export const listProjectAgreement = function (cond = {}) {
  return Axios.post('/project/listProjectAgreement', cond)
}

// 查项目可支配费用-查调整绩效数据
export const selectAdjustmentDetailByProjectId = function (cond) {
  return Axios.post('/projectDisposableExpenses/selectAdjustmentDetailByProjectId/' + cond)
}

// 调整绩效数据-保存
export const updateAdjustment = function (cond) {
  return Axios.post('/projectDisposableExpenses/updateAdjustment', cond)
}

// 查项目可支配费用-log列表
export const listLog = function (cond) {
  return Axios.post('/projectDisposableExpenses/listLog', cond)
}

// 查项目可支配费用-查看log详情
export const selectLogById = function (cond) {
  return Axios.post('/projectDisposableExpenses/selectLogById/' + cond)
}

// 项目导出
export const projectDisposableExpensesExport = function (cond = {}) {
  return Axios({
    url: '/project/projectDisposableExpensesExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//特殊发票申请-没有关联合同的项目列表
export const listUncontractedProject = function (cond = {}) {
  return Axios.post('/project/listUncontractedProject', cond)
}
